<template>
  <div>
    <!-- 手机端 -->
    <div class="phone">
      <div class="boxbg d-flex" v-for="(item, index) in gunarr" :key="index">
        <img class="boximg" :src="item.box.img" alt />
        <div class="boxname">
          <p class="boxname-p">
            {{ item.activity.boxName }}
            <span>(vip等级{{ item.activity.ttLevel }})</span>
          </p>
          <p class="receive" @click="openbox(item.activity.id)"></p>
          <!-- {{item.statusColumn}} -->
          <!-- <p class="receive" @click="openbox()">可领取</p> -->
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <!-- <div class="pop">
    </div> -->
  </div>
</template>

<script>
import { getwelfare, opentwelfareAPI } from "../../../api/index";
export default {
  data() {
    return {
      gunarr: [],
    };
  },
  created() {
    getwelfare(7).then((res) => {
      console.log(res);
      this.gunarr = res.data.data;
    });
  },
  methods: {
    openbox(id) {
      opentwelfareAPI(id).then((res) => {
        // console.log(res.data.data);
        if (res.data.code == 200) {
          this.$message.success(`领取成功！`);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.phone {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  .boxbg {
    width: 45%;
    height: 165px;
    background: url("~@/assets/images/Welfare/rw.png") no-repeat;
    background-size: 100% 100%;
    align-items: center;
    justify-content: space-evenly;

    .boximg {
      width: 80px;
      height: 70px;
      display: inline-block;
      margin: 0 0 24px -7px;
    }
    .boxname {
      .boxname-p {
        font-size: 17px;
        color: white;
        display: inline-block;
        margin: 15px 0;
        span {
          font-size: 12px;
          color: yellow;
        }
      }
      .receive {
        width: 134px;
        height: 34px;
        color: white;
      }
    }
  }
}
// .pop{
//     position: fixed;
//     width: 500px;
//     height: 500px;
//     z-index: 999;
//     background-color: black;
//     margin: auto;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//   }
@media (max-width: 550px) {
  .phone {
    padding: 0 10px;
    display: block;
    .boxbg {
      width: 98%;
      height: 165px;
      background: url("~@/assets/images/Welfare/rw.png") no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: space-evenly;
      .boximg {
        width: 69px;
        height: 48px;
        display: inline-block;
      }
      .boxname {
        .boxname-p {
          font-size: 17px;
          color: white;
          display: inline-block;
          margin: 15px 0;
          span {
            font-size: 12px;
            color: yellow;
          }
        }
        .receive {
          color: white;
          width: 100px;
          height: 50px;
        }
      }
    }
  }
}
</style>
